import React, { useEffect, useRef, useState } from 'react';
import './Select.css';
import PropTypes from 'prop-types';
import { Label } from '../Label';
import Input from '../Input/Input';
import { isEmpty } from '../../../utils/validation';
import { DownArrow } from '../../../assets/icons';

const Select = ({ list = [], label, color, className, onChange, selectedValue = '', required = false, disabled = false }) => {
	const select = useRef(null);
	const option = useRef(null);

	const id = `select_id_${(Math.floor((Math.random() * new Date().getMilliseconds()) + 1))}`;

	const classes = className !== '' ? className : 'border-t-0 border-l-0 border-r-0 border-b-2 border-b-maroon rounded-md min-w-full w-full px-4 pb-2 bg-white';

	let labelComponent;

	if (typeof label === 'string') {
		labelComponent = <Label className={'flex flex-col'} title={label} />;
	} else if (React.isValidElement(label)) {
		labelComponent = <React.Fragment>{label}</React.Fragment>
	} else {
		labelComponent = <></>;
	}

	const [selectList, setSelectList] = useState(list);
	const [searchVisible, setSearchVisible] = useState(false);
	const [optionsListVisible, setOptionsListVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [itemSelected, setItemSelected] = useState(null);
	const [disableSelectDefaultValue, setDisableSelectDefaultValue] = useState(false);
	const [inputHidden, setInputHidden] = useState(false);

	useEffect(() => {
		// Si viene empty la primera inicialización del componente, significa que todos los cambios que sufra la lista no tiene que tener un valor por defecto seleccionado
		if (isEmpty(selectedValue)) {
			setDisableSelectDefaultValue(true);
		}

		return () => {
			setDisableSelectDefaultValue(false);
		}
	}, [selectedValue]);

	useEffect(() => {
		if (searchVisible === true) {
			setSearch('');
			setItemSelected(list[0]);
		}
	}, [searchVisible, list]);

	useEffect(() => {
		let listAux = [].concat(list);

		if (!isEmpty(search)) {
			const firstItem = listAux.shift();

			const newList = listAux.filter(e => e.title.toUpperCase().includes(search.toUpperCase()));
			newList.unshift(firstItem);

			setSelectList(newList);
		} else {
			setSelectList(listAux);
		}
	}, [search, list]);

	useEffect(() => {
		if (className.includes('data_search')) {
			setSearchVisible(true);
		} else {
			setSearchVisible(false);
		}

		if (className.includes('input_hidden')) {
			setInputHidden(true);
		} else {
			setInputHidden(false);
		}
	}, [className]);

	useEffect(() => {
		if (disableSelectDefaultValue === false) {
			if (searchVisible === true && !isEmpty(list) && !isEmpty(selectedValue)) {
				const item = list.find(e => e.value == selectedValue);

				if (!isEmpty(item))
					setItemSelected(item);
			}
		}
	}, [searchVisible, selectedValue, list, disableSelectDefaultValue]);

	const handleClickOutside = (event) => {
		if (select.current && option.current) {
			if (!select.current.contains(event.target) && !option.current.contains(event.target)) {
				setOptionsListVisible((select.current.contains(event.target) && option.current.contains(event.target)));
			}
		}
	};

	useEffect(() => {
		// Agregar el event listener al montar el componente
		document.addEventListener('mousedown', handleClickOutside);

		// Eliminar el event listener al desmontar el componente
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickFirstOption = () => {
		setOptionsListVisible(!optionsListVisible);
	}

	const classItemSelected = `w-full pe-6 md:pe-8 cursor-default ${disabled === true ? 'text-light-grey-disabled' : 'text-[#666666]'} text-base font-semibold text-nowrap overflow-hidden`;

	const handleClickItem = (val, event) => {
		event.target.selectedOptions = [{ innerText: val.title }];

		setItemSelected(val);
		handleClickFirstOption();
		
		const newEvent = { ...event, target: { ...event.target, value: event.target.getAttribute('value') } };
		onChange(newEvent);
	}

	if (searchVisible === true) {
		return (
			<>
				<div className='relative select w-full'>
					{labelComponent}
					<div ref={option} className="relative">
						{
							isEmpty(selectedValue) ?
								<div onClick={disabled === true ? () => { } : handleClickFirstOption} className={className} value={isEmpty(itemSelected) ? `${selectList[0].value}` : `${itemSelected.value}`} required={required}>
									<Input className={classItemSelected} value={isEmpty(itemSelected) ? `${selectList[0].title}` : `${itemSelected.title}`} editable={false} />
									<DownArrow size={16} color={'#666666'} className={`absolute top-[42%] end-4`} />
								</div>
								:
								<div onClick={disabled === true ? () => { } : handleClickFirstOption} className={className} value={itemSelected?.value} required={required}>
									<Input className={classItemSelected} value={itemSelected?.title} editable={false} />
									<DownArrow size={16} color={'#666666'} className={`absolute top-[42%] end-4`} />
								</div>
						}
					</div>
					<div ref={select} id={`options_${id}`} className={`${optionsListVisible === true ? '' : 'hidden'} absolute z-20 bg-white w-full pt-1 shadow-2xl rounded-md overflow-hidden`}>
						<div className="overflow-auto max-h-80 md:max-h-64 relative options_select_search">
							<div className={`${inputHidden === true ? 'hidden' : ''} sticky top-0 w-full bg-white pb-2 px-3`}>
								<Input type='search' placeholder='Buscar...' className='mt-2 text-sm font-semibold px-4 py-2 w-full placeholder:text-light-grey-disabled placeholder:font-semibold focus:rounded-lg focus:border-shiny-pink border-maroon rounded-lg border-2 focus:outline-none' handleChange={(e) => { setSearch(e.target.value); }} value={search} />
							</div>
							<ul>
								{
									selectList.map((e, idx) => {
										if (idx === 0) {
											return <li key={`key_option_select_${selectedValue}_${idx}`} value={`${e.value}`} className='cursor-default py-1.5 px-3 text-sm font-semibold text-light-grey-disabled'>{e.title}</li>
										} else {
											return <li key={`key_option_select_${selectedValue}_${idx}`} value={`${e.value}`} className={`cursor-default py-1.5 px-3 font-normal text-sm ${e.value === itemSelected?.value ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`} onClick={(i) => { handleClickItem(e, i) }}>{e.title}</li>
										}
									})
								}
							</ul>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className='select w-full'>
			{labelComponent}
			<div className="relative">
				<select id={id} defaultValue={selectList[0].value ?? null} style={{ 'color': color }} className={classes} onChange={onChange} required={required} disabled={disabled}>
					{
						selectList.map((e, idx) => {
							// eslint-disable-next-line eqeqeq
							if (e.value == selectedValue)
								return <option key={`key_option_select_${selectedValue}_${idx}`} value={`${e.value}`} selected>{e.title}</option>
							else
								return <option key={`key_option_select_${selectedValue}_${idx}`} value={`${e.value}`}>{e.title}</option>
						})
					}
				</select>
			</div>
		</div>
	);
};

Select.propTypes = {
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	list: PropTypes.array.isRequired,
	color: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	selectedValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	required: PropTypes.bool
};

export default Select;