import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import ssn_img from '../../assets/img/ssn.png'
import { scrollToTop } from '../../utils/functions';
import { useMyContext } from '../../context/global/Context';
import { isEmpty, sanitizePhoneNumber } from '../../utils/validation';

const Footer = () => {

	const { sellPhoneNumber, parametersSaleCenter } = useMyContext();

	return (
		<div className='footer w-full h-[419px] flex justify-between flex-col items-center'>
			<div className='w-full border-t-[3px] border-t-light-grey bg-white'>
				<div className='lg:w-3/4 xl:w-10/12 2xl:w-11/12 w-11/12 h-full flex justify-between items-center flex-col mx-auto'>
					<div className='md:h-[300px] w-full flex md:flex-row flex-col h-auto justify-between items-center'>
						<div className='h-full flex flex-col md:justify-start md:items-start justify-center items-center'>
							<div className='py-2.5 md:pr-2.5'>
								<h5 className='text-black text-base md:text-left text-center font-semibold uppercase leading-normal tracking-wide py-4 px-2.5'>Sobre ATM</h5>
							</div>
							<ul className='flex flex-col text-grey text-base md:text-left text-center font-normal leading-normal tracking-wide'>
								<li className='py-4 px-2.5'>
									<Link to={'/'} onClick={scrollToTop}>Home</Link>
								</li>
								{/* <Link><li className='py-4 px-2.5'>Sobre ATM</li></Link> */}
								<li className='py-4 px-2.5'>
									<Link to={'/'} onClick={scrollToTop}>Cotizar</Link>
								</li>
							</ul>
						</div>
						<div className='h-full flex flex-col md:justify-start md:items-start justify-center items-center'>
							<div className='py-2.5 md:pr-2.5'>
								<h5 className='text-black text-base md:text-left text-center font-semibold uppercase leading-normal tracking-wide py-4 px-2.5'>Contacto</h5>
							</div>
							<ul className='flex flex-col text-grey md:text-left text-center text-base font-normal leading-normal tracking-wide'>
								{
									isEmpty(parametersSaleCenter.id) ?
										<li className='py-4 px-2.5'>
											<a href={`tel:${sanitizePhoneNumber(sellPhoneNumber)}`} target='_blank' rel='noreferrer'>Ventas | {sellPhoneNumber}</a>
										</li>
										:
										<></>
								}
								<li className='py-4 px-2.5'>
									<a href="tel:08103450492" target='_blank' rel='noreferrer'>Atención al asegurado | 0810-345-0492</a>
								</li>
								<li className='py-4 px-2.5'>
									<a href='mailto:contacto@atmseguros.com' target='_blank' rel='noreferrer'>contacto@atmseguros.com</a>
								</li>
							</ul>
						</div>
						<div className='h-full flex flex-col md:justify-start md:items-start justify-center items-center'>
							<div className='py-2.5 md:pr-2.5'>
								<h5 className='text-black text-base md:text-left text-center font-semibold uppercase leading-normal tracking-wide py-4 px-2.5'>Encontranos en redes</h5>
							</div>
							<ul className='flex flex-col text-grey md:text-left text-center text-base font-normal leading-normal tracking-wide'>
								<li className='py-4 px-2.5'>
									<a href='https://www.facebook.com/ATMCiadeSeguros' rel='noreferrer' target='_blank'>Facebook</a>
								</li>
								<li className='py-4 px-2.5'>
									<a href="https://www.instagram.com/atmseguros?igsh=dGdicHIwNWczeTZp" rel='noreferrer' target='_blank'>Instagram</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='w-full flex lg:flex-row flex-col items-center justify-between border-t-[3px] border-t-light-grey'>
				<div className='xl:w-[65%] lg:w-[70%] w-full flex flex-col items-center justify-between'>
					<div className='w-full bg-white'>
						<div className='xl:w-10/12 2xl:w-11/12 w-10/12 h-full flex justify-between mx-auto items-baseline flex-col'>
							<div className="grid md:hidden grid-cols-2 text-black text-xs font-normal leading-tight py-6">
								<div className="flex flex-col text-center items-center justify-between gap-y-6 h-full">
									<p>Nº de Inscripción SSN<br />0812</p>
									<a href='tel:8006668400' target='_blank' rel='noreferrer' className='font-bold md:text-base text-sm text-blue'>0800-666-8400</a>
								</div>
								<div className="flex flex-col text-center items-center justify-between gap-y-6 h-full">
									<p>Departamento de Orientación<br />y Asistencia al Asegurado</p>
									<Link to='https://www.argentina.gob.ar/ssn' target='_blank' className='font-bold md:text-base text-sm text-blue'>www.argentina.gob.ar/ssn</Link>
								</div>
							</div>
							<div className="hidden md:flex flex-row text-black text-xs font-normal leading-tight items-center w-full justify-between md:py-6">
								<div className="flex flex-row">
									<p className='text-center'>Nº de Inscripción SSN<br />0812</p>
								</div>
								<div className='3sm:bg-grey 3sm:w-[1px] 3sm:h-8 hidden md:block md:mx-4'></div>
								<div className="flex flex-row gap-x-8 xl:gap-x-16">
									<p className='text-center'>Departamento de Orientación<br />y Asistencia al Asegurado</p>
									<a href='tel:8006668400' target='_blank' rel='noreferrer' className='font-bold md:text-base text-sm text-blue'>0800-666-8400</a>
								</div>
								<div className='3sm:bg-grey 3sm:w-[1px] 3sm:h-8 hidden md:block md:mx-4'></div>
								<div className="flex flex-row">
									<Link to='https://www.argentina.gob.ar/ssn' target='_blank' className='font-bold md:text-base text-sm text-blue'>www.argentina.gob.ar/ssn</Link>
								</div>
							</div>
						</div>
					</div>
					<div className='w-full bg-neutral py-8'>
						<div className='xl:w-10/12 2xl:w-11/12 w-10/12 h-full flex justify-between items-center flex-col mx-auto'>
							<div className='h-auto py-3 md:py-0 w-full flex justify-between items-center'>
								<div className='flex w-full flex-col h-full justify-center items-center text-left text-black text-xs font-normal leading-tight gap-4'>
									<div>La entidad aseguradora dispone de un Servicio de Atención al Asegurado que atenderá las consultas y reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derechohabientes.</div>
									<div>En caso de que existiera un reclamo ante la entidad aseguradora y que el mismo no haya sido resuelto o haya sido desestimado, total o parcialmente, o que haya sido denegada su admisión, podrá comunicarse con la Superintendencia de Seguros de la Nación por teléfono al 0800-666-8400, correo electrónico a consultas@ssn.gob.ar o formulario disponible en la página argentina.gob.ar/ssn</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='xl:w-[35%] lg:w-[30%] w-full h-full flex flex-col items-center justify-between bg-white xl:px-20 px-10'>
					<a href='https://www.argentina.gob.ar/ssn' target='_blank' rel='noreferrer'><img src={ssn_img} alt='SSN IMG' className='w-[242px] py-6' /></a>
					<div className='flex w-full pb-5 flex-col h-full justify-between items-left text-left text-black text-xs font-normal leading-tight'>
						El Servicio de Atención al Asegurado (SAA) está integrado por:
						<span className='py-2'></span>
						RESPONSABLE Fernando Rodríguez<br />
						SUPLENTE Paola Candia
						<span className='py-2'></span>
						<span>EMAIL <a href="mailto:saa@atmseguros.com.ar" target="_blank" rel="noreferrer">saa@atmseguros.com.ar</a></span>
						<span>TELÉFONO <a href="tel:+5491152390230" target='_blank' rel='noreferrer'>1152390230</a></span>
					</div>
				</div>
			</div>
		</div>
	);
};


export default Footer;