import React, { useRef, useState } from "react";
import { ErrorModal, Modal } from "../../components";
import { Button, Input, Label, Select } from "../../components/basic";
import { defaultTypeVehicles } from "../../config";
import { useCancellationForm, useFetchGoogleAnalytics } from "../../hooks";
import { isEmpty, onlyLetter, onlyNumber, showErrorInput, validateEmail, validateLicence, validatePhoneNumber, validateRequiredFields } from "../../utils/validation";
import { useMyContext } from "../../context/global/Context";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_API_KEY } from "../../config/constants";

export default function CancellationRequest() {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const [modalType, setModalType] = useState('plain');
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const { functions: { cancelRequest } } = useMyContext();
    const { cancellationForm, modifyCancellationForm } = useCancellationForm();
    const { refund } = useFetchGoogleAnalytics();

    const recaptcha = useRef();

    const handleChangeData = (type, value) => {
        switch (type) {
            case 'nombre':
                if (onlyLetter(value)) modifyCancellationForm({ Nombre: value });
                break;
            case 'apellido':
                if (onlyLetter(value)) modifyCancellationForm({ Apellido: value });
                break;
            case 'dni':
                if (onlyNumber(value)) modifyCancellationForm({ Dni: value });
                break;
            case 'email':
                modifyCancellationForm({ Email: value });
                break;
            case 'area':
                if (validatePhoneNumber(value)) modifyCancellationForm({ Area: value });
                break;
            case 'telefono':
                if (validatePhoneNumber(value)) modifyCancellationForm({ Telefono: value });
                break;
            case 'ramo':
                modifyCancellationForm({ Ramo: value });
                break;
            case 'patente':
                modifyCancellationForm({ Patente: value.toUpperCase() });
                break;
            default:
                break;
        }
    }

    const handleCancellationRequest = async () => {
        const validationEmail = !isEmpty(cancellationForm.Email) ? validateEmail(cancellationForm.Email) : true;
        const validationLicense = !isEmpty(cancellationForm.Patente) ? validateLicence(cancellationForm.Patente, cancellationForm.Ramo) : true;

        if (validateRequiredFields('', 'cancellation-request') && validationEmail && validationLicense) {
            setLoading(true);

            const token = await recaptcha.current.executeAsync();
            recaptcha.current.reset();

            await cancelRequest({ ...cancellationForm, ...{ RecaptchaToken: token } })
                .then((response) => {
                    setLoading(false);

                    if (response.error === true) {
                        setModalTitle('Atención');
                        setModalType('error');
                    } else {
                        setModalTitle('Petición de baja');
                        setModalType('info');
                    }

                    setModalText(response.msg);
                    setModalShow(true);

                    refund();
                });
        } else {
            if (!validationEmail) {
                showErrorInput('input_email', 'Debes ingresar un email válido.');
            }

            if (!validationLicense) {
                showErrorInput('input_license', 'Debes ingresar una patente válida.');
                showErrorInput('input_license_mobile', 'Debes ingresar una patente válida.');
            }
        }
    }

    const labelClass = 'text-left mb-1 ml-2 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap';

    return (
        <>
            <ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
            <div id="cancellation-request" className="container xl:w-3/4 mx-auto mt-8 mb-16 md:mb-20 px-6 md:px-0">
                <Modal show={showModal} showFunction={setShowModal} handleClose={() => { setShowModal(false) }} handleConfirm={() => { setShowModal(false) }} title={'Solo se podrá solicitar la baja de la póliza a través de este medio en el caso que la haya contratado telefónicamente o por internet. Caso contrario, deberá realizar el trámite con el productor asesor de seguros donde contrató la póliza.'} type={'info'} />
                <div className="form_cancellation">
                    <h1 className="text-center font-bold text-2xl md:text-4xl text-primary">Solicitá la baja de tu seguro</h1>
                    <div className='flex flex-col gap-6 w-full mt-10 mb-10 md:mt-12 mb:mb-16'>
                        <div className='grid grid-cols-2 gap-x-6 md:gap-x-10 gap-y-6'>
                            <Input type='text' label={<Label className={labelClass} title={'Nombre *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Nombre} handleChange={(e) => { handleChangeData('nombre', e.target.value) }} required />
                            <Input type='text' label={<Label className={labelClass} title={'Apellido *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Apellido} handleChange={(e) => { handleChangeData('apellido', e.target.value) }} required />
                            <Input type='text' label={<Label className={labelClass} title={'DNI *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Dni} handleChange={(e) => { handleChangeData('dni', e.target.value) }} required maxLength={8} />
                            <Input name="input_email" type='email' label={<Label className={labelClass} title={'E-mail *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Email} handleChange={(e) => { handleChangeData('email', e.target.value) }} required />
                            <div className="hidden md:flex gap-4">
                                <div className="w-min md:w-1/4">
                                    <Input name='area_phone' type='text' label={<Label className={labelClass} title={'Área *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded  focus:outline-none'} size={''} value={cancellationForm.Area} handleChange={(e) => { handleChangeData('area', e.target.value) }} required />
                                </div>
                                <div className="w-full">
                                    <Input name='number_phone' type='text' label={<Label className={labelClass} title={'Teléfono *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded  focus:outline-none'} value={cancellationForm.Telefono} handleChange={(e) => { handleChangeData('telefono', e.target.value) }} required />
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <Select label={<Label className={labelClass} title={'Tipo de vehículo *'} />} list={defaultTypeVehicles} selectedValue={cancellationForm.Ramo} onChange={(e) => handleChangeData('ramo', e.target.value)} className={'data_search input_hidden border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 focus:rounded focus:outline-none bg-white'} required />
                            </div>
                            <div className="hidden md:block">
                                <Input name="input_license" type='text' label={<Label className={labelClass} title={'Patente *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Patente} handleChange={(e) => { handleChangeData('patente', e.target.value) }} required maxLength={7} />
                            </div>
                        </div>
                        <div className="flex flex-row gap-x-6 md:hidden">
                            <div className="w-2/6">
                                <Input name='area_phone' type='text' label={<Label className={labelClass} title={'Área *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded  focus:outline-none'} size={''} value={cancellationForm.Area} handleChange={(e) => { handleChangeData('area', e.target.value) }} required />
                            </div>
                            <div className="w-full">
                                <Input name='number_phone' type='text' label={<Label className={labelClass} title={'Teléfono *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded  focus:outline-none'} value={cancellationForm.Telefono} handleChange={(e) => { handleChangeData('telefono', e.target.value) }} required />
                            </div>
                        </div>
                        <div className='grid grid-flow-col-dense gap-x-6 gap-y-6 md:hidden'>
                            <Select label={<Label className={labelClass} title={'Tipo de vehículo *'} />} list={defaultTypeVehicles} selectedValue={cancellationForm.Ramo} onChange={(e) => handleChangeData('ramo', e.target.value)} className={'data_search input_hidden border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 focus:rounded focus:outline-none bg-white'} required />
                            <Input name="input_license_mobile" type='text' label={<Label className={labelClass} title={'Patente *'} />} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={cancellationForm.Patente} handleChange={(e) => { handleChangeData('patente', e.target.value) }} required maxLength={7} />
                        </div>
                        <ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_API_KEY} size='invisible' />
                        <Button title="solicitar la baja" className={'uppercase bg-deep-pink text-white rounded-md py-2 font-semibold mt-6 hover:bg-primary hover:border-opacity-5 hover:border-maroon'} size={'full'} handleClick={handleCancellationRequest} loading={loading} />
                    </div>
                </div>
            </div>
        </>
    );
}