import { useEffect, useState } from "react";
import { useMyContext } from "../context/global/Context";
import { isEmpty } from "../utils/validation";
import { defaultPaymentData } from "../config";
import { getPaymentMethods } from "../services";

const initial = defaultPaymentData;

export default function useFetchPaymentMethods() {
    const { activeProduct, salesCenterHash } = useMyContext();

    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        (async () => {
            if (!isEmpty(activeProduct.id)) {
                const response = await getPaymentMethods(activeProduct.id, salesCenterHash);

                if (!isEmpty(response)) {
                    const { error, body } = response;

                    if (error === false)
                        setPaymentMethods(body);
                    else
                        setPaymentMethods(initial);
                } else {
                    setPaymentMethods(initial);
                }
            } else {
                setPaymentMethods(initial);
            }
        })();
    }, [activeProduct.id, salesCenterHash]);

    return { paymentMethods };
}